<template>
	<div class="auth-wrapper">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AuthWrapper',
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.auth-wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;

	@include max-screen($md) {
		justify-content: center;
	}
}
</style>
