class Analytics {
	trackEvent = ({ event, value, label }) => {
		if (this.$gtm) {
			this.$gtm.trackEvent({
				event,
				value,
				label,
			});
		}
	};
}

const analytics = new Analytics();
export default analytics;
