<template>
	<form class="form" v-on="$listeners">
		<slot />
	</form>
</template>

<script>
export default {
	name: 'CForm',
	props: {
		submit: {
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
.form {
	display: flex;
	flex-direction: column;
}
</style>
