<template>
	<div v-if="feedback" class="customer-feedback">
		<img src="../../assets/icons/quote-icon.svg" alt="quote-icon" class="customer-feedback__icon" />
		<h2 class="customer-feedback__message">
			{{ feedback.message }}
		</h2>
		<p class="customer-feedback__name">- {{ feedback.customerName }}</p>
	</div>
</template>

<script>
import { customerFeedbackByBrand } from '@/utils/customerFeedback';

export default {
	name: 'CustomerFeedback',
	data() {
		return {
			brandFeedback: customerFeedbackByBrand[process.env.VUE_APP_BRAND.toLowerCase()],
		};
	},
	computed: {
		feedback() {
			return this.brandFeedback ? this.brandFeedback[Math.floor(Math.random() * this.brandFeedback.length)] : null;
		},
	},
};
</script>
<style lang="scss">
.customer-feedback {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: start;
	padding: 0 3rem;

	&__icon {
		max-width: 55px;
	}
	&__feedback {
	}
	&__name {
	}
}
</style>
