<template>
	<div class="material-input__component" :class="computedClasses">
		<input
			v-if="type === 'email'"
			:id="id"
			v-model="valueCopy"
			type="email"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>
		<input
			v-if="type === 'url'"
			:id="id"
			v-model="valueCopy"
			type="url"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>
		<input
			v-if="type === 'number'"
			:id="id"
			v-model="valueCopy"
			type="number"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:max="max"
			:min="min"
			:step="step"
			:minlength="minlength"
			:maxlength="maxlength"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>
		<input
			v-if="type === 'password'"
			:id="id"
			v-model="valueCopy"
			:type="passwordFieldType"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:max="max"
			:min="min"
			:step="step"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>
		<input
			v-if="type === 'tel'"
			:id="id"
			v-model="valueCopy"
			type="tel"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>
		<input
			v-if="type === 'text'"
			:id="id"
			v-model="valueCopy"
			type="text"
			class="material-input"
			:name="name"
			:placeholder="placeholder"
			:readonly="readonly"
			:disabled="disabled"
			:autocomplete="autocomplete"
			:minlength="minlength"
			:maxlength="maxlength"
			:required="required"
			@animationstart="checkAnimation"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
			@input="handleModelInput"
		/>

		<button
			v-if="type === 'password'"
			type="button"
			class="visibility-toggler"
			tabindex="-1"
			@click="togglePasswordVisibility"
		>
			<img :src="visibilityTogglerIcon" alt="visibility-toggler" class="visibility-toggler__icon" />
		</button>

		<span class="material-input-bar" />

		<label class="material-label">
			{{ label }}
		</label>

		<div v-if="error" class="material-errors">
			<FormError :error="error" class="material-error" />
		</div>
	</div>
</template>

<script>
import FormError from '@/shared/forms/FormError';
import visibleIcon from '@/assets/icons/visible-icon.svg';
import hiddenIcon from '@/assets/icons/hidden-icon.svg';

export default {
	name: 'MaterialInput',
	components: { FormError },
	props: {
		id: {
			type: String,
			default: null,
		},
		name: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		min: {
			type: String,
			default: null,
		},
		max: {
			type: String,
			default: null,
		},
		step: {
			type: Number,
			default: null,
		},
		minlength: {
			type: Number,
			default: null,
		},
		maxlength: {
			type: Number,
			default: null,
		},
		required: {
			type: Boolean,
			default: false,
		},
		autocomplete: {
			type: String,
			default: null,
		},
		error: {
			type: String,
			default: null,
		},
		textCenter: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			valueCopy: null,
			focus: false,
			valid: true,
			passwordVisible: false,
			autofilled: false,
		};
	},
	computed: {
		computedClasses() {
			return {
				'material--active': this.focus,
				'material--disabled': this.disabled,
				'material--has-errors': Boolean(!this.valid || this.error),
				'material--center': this.textCenter,
				'material--raised':
					Boolean(
						this.focus ||
							this.valueCopy || // has value
							(this.placeholder && !this.valueCopy)
					) || // has placeholder
					this.autofilled, // has been autofilled
			};
		},
		passwordFieldType() {
			return this.passwordVisible ? 'text' : 'password';
		},
		visibilityTogglerIcon() {
			return this.passwordVisible ? visibleIcon : hiddenIcon;
		},
	},
	watch: {
		value(newValue) {
			// This watch works from the code side of the 2-way-binding:
			this.copyValue(newValue);
		},
	},
	beforeMount() {
		// Here we are following the Vue2 convention on custom v-model:
		// https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
		this.copyValue(this.value);
	},
	methods: {
		handleModelInput(event) {
			this.$emit('input', event.target.value, event);
			this.handleValidation();
		},
		handleFocus(focused) {
			this.focus = focused;
		},
		handleValidation() {
			this.valid = this.$el ? this.$el.querySelector('.material-input').validity.valid : this.valid;
		},
		copyValue(value) {
			this.valueCopy = value;
			this.handleValidation();
		},
		togglePasswordVisibility() {
			this.passwordVisible = !this.passwordVisible;
		},
		checkAnimation(e) {
			const { animationName } = e;
			if (animationName.includes('onAutoFillStart')) {
				this.autofilled = true;
			} else if (animationName.includes('onAutoFillCancel')) {
				this.autofilled = false;
			}
		},
	},
};
</script>
