<template>
	<AuthWrapper>
		<AuthLayout>
			<div class="aside">
				<div class="feedback-wrapper">
					<CustomerFeedback />
				</div>
			</div>
			<div class="main">
				<AuthFormWrapper>
					<PageTitle big>
						{{ $t('login.title') }}
					</PageTitle>
					<CForm v-if="!success" @submit.prevent="checkForm">
						<MaterialInput id="email" v-model="email" :label="$t('login.email')" name="email" />
						<MaterialInput
							id="password"
							v-model="password"
							name="password"
							:label="$t('login.password')"
							type="password"
						/>
						<div class="forgot-password">
							<a href="/forgot-password">
								{{ $t('login.forgot-password') }}
							</a>
						</div>
						<CButton primary full-width type="submit" :success="success" :loading="loading">
							{{ $t('login.button') }}
						</CButton>

						<FormError :error="error" />
					</CForm>

					<div class="sign-up">
						{{ $t('login.dont-have-account') }}
						<a class="sign-up__link" href="/signup">
							{{ $t('registration.sign-up') }}
						</a>
					</div>
				</AuthFormWrapper>
			</div>
		</AuthLayout>
	</AuthWrapper>
</template>

<script>
import { mapActions } from 'vuex';
import MaterialInput from '@/components/material-input/MaterialInput';
import AuthLayout from '@/shared/auth-layout/AuthLayout';
import CustomerFeedback from '@/shared/customer-feedback/CustomerFeedback';
import CButton from '@/shared/cbutton/CButton';
import CForm from '@/shared/cform/CForm';
import FormError from '@/shared/forms/FormError';
import AuthWrapper from '@/shared/auth-wrapper/AuthWrapper';
import AuthFormWrapper from '@/shared/auth-form-wrapper/AuthFormWrapper';
import PageTitle from '@/components/page-title/PageTitle';

export default {
	name: 'Login',
	components: {
		PageTitle,
		AuthFormWrapper,
		AuthWrapper,
		FormError,
		CForm,
		CButton,
		MaterialInput,
		AuthLayout,
		CustomerFeedback,
	},
	data() {
		return {
			loading: false,
			email: '',
			password: '',
			error: null,
			success: false,
		};
	},
	methods: {
		...mapActions(['login']),
		checkForm() {
			this.error = null;
			if (!this.password || !this.email) this.error = this.$t('errors.missing-email-or-password');
			else {
				this.submitForm();
			}
		},
		async submitForm() {
			try {
				this.loading = true;
				await this.login({ email: this.email, password: this.password });
				this.success = true;
			} catch (e) {
				if (e.response?.data?.error) {
					console.error(e);
					this.error = e.response.data.error.reason ?? e.response.data.error.message;
				} else {
					console.error(e);
					this.error = e.message;
				}
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/variables';
@import 'src/assets/style/mixin';

.sign-up {
	margin-top: 1rem;
	color: rgba(255, 255, 255, 0.6);

	&__link {
		font-weight: bold;
		color: #ffffff;

		&:hover {
			color: $mantis-green;
		}
	}
}

.forgot-password {
	margin-top: 1em;
	text-align: right;
}

.feedback-wrapper {
	@media (max-width: $md) {
		& {
			display: none;
		}
	}
}
</style>
