<template>
	<div class="auth-form-wrapper">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AuthFormWrapper',
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/variables';

.auth-form-wrapper {
	width: 50%;
	padding: 2rem 0.5rem 0.5rem;
	min-width: 380px;

	@media (max-width: $md) {
		& {
			width: 100%;
			min-width: unset;
		}
	}
}
</style>
