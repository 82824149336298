<template>
	<div class="material-input__component" :class="computedClasses">
		<vue-tel-input
			v-bind="bindProps"
			ref="input"
			v-model="number"
			input-classes="material-input"
			@input="checkMobileNumber"
			@validate="validateMobileNumber"
			@focus="handleFocus(true)"
			@blur="handleFocus(false)"
		/>

		<span class="material-input-bar" />

		<label class="material-label">
			{{ label }}
		</label>

		<div v-if="error" class="material-errors">
			<FormError :error="error" class="material-error" />
		</div>
	</div>
</template>
<script>
import { VueTelInput } from 'vue-tel-input';
import FormError from '@/shared/forms/FormError';

export default {
	name: 'MaterialPhoneInput',
	components: {
		VueTelInput,
		FormError,
	},
	props: {
		isInvalid: Boolean,
		mobilenumber: String,
		defaultCountry: String,
		error: String,
		label: String,
	},
	data() {
		return {
			focus: false,
			number: '',
			phone: {
				number: '',
				isValid: false,
			},
			bindProps: {
				mode: 'international',
				disabledFetchingCountry: true,
				disabled: false,
				disabledFormatting: false,
				defaultCountry: this.defaultCountry,
				isInvalid: this.isInvalid,
				dynamicPlaceholder: true,
				required: false,
				enabledCountryCode: false,
				enabledFlags: true,
				onlyCountries: [],
				ignoredCountries: ['US', 'IR'],
				autocomplete: 'on',
				name: 'telephone',
				maxLen: 25,
			},
		};
	},
	computed: {
		computedClasses() {
			return {
				'material--active': this.focus,
				'material--disabled': this.disabled,
				'material--has-errors': Boolean(!this.phone.isValid || this.error),
				'material--raised': true,
			};
		},
	},
	watch: {
		defaultCountry(newVal) {
			if (!this.mobilenumber || this.mobilenumber.length < 1) {
				this.$refs.input.choose(newVal);
			}
		},
		mobilenumber(newVal) {
			if ((!this.number || this.number.length < 1) && newVal !== null) {
				this.number = newVal;
			}
		},
	},
	beforeMount() {
		if (this.mobilenumber !== null) {
			this.number = this.mobilenumber;
		}
	},
	methods: {
		checkMobileNumber(value, { valid }) {
			this.phone.number = value;
			this.phone.isValid = valid;
			this.$emit('set-mobile-number', {
				number: this.phone.number,
				isValid: this.phone.isValid,
			});
		},
		validateMobileNumber({ number, valid }) {
			this.phone.number = number;
			this.phone.isValid = valid;
			this.$emit('set-mobile-number', {
				number: this.phone.number,
				isValid: this.phone.isValid,
			});
		},
		handleFocus(focused) {
			this.focus = focused;
		},
	},
};
</script>
<style lang="scss" scoped>
@import 'src/assets/style/variables';

.material-input__component:not(.material--raised) {
	.material-label {
		left: 55px;
	}
}

::v-deep .vti__input {
	font-size: $title;
	padding-left: 65px !important;
	background: none;
	padding: 10px 10px 10px 5px;
}

::v-deep .vue-tel-input {
	border: none;
	border-bottom: 1px solid $border-color;
	position: relative;
}

::v-deep .vti__dropdown {
	width: 65px;
	position: absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;

	&.open,
	&:hover {
		background-color: transparent;
	}
}

::v-deep .vti__flag {
	border-radius: 14px;
	width: 16px;
}

::v-deep .vti__dropdown-list {
	max-width: $small-width;
}

::v-deep .vti__dropdown-item {
	background: #27282c;
}

::v-deep .vti__dropdown-item.highlighted {
	background-color: #525256;
}

::v-deep .vti__dropdown-list {
	border: 0px;
	background-color: transparent;
}
</style>
