<template>
	<div class="auth-layout">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'AuthLayout',
};
</script>

<style scoped lang="scss">
@import '../../assets/style/variables';

.auth-layout {
	display: flex;
	flex: 1;

	@media (max-width: $md) {
		& {
			flex-direction: column;
		}
	}

	.aside {
		flex: 4;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media (max-width: $xl) {
			flex: 5;
		}

		@media (max-width: $md) {
			display: none;
		}
	}

	.main {
		padding-top: $auth-layout-header-padding;
		flex: 5;
		margin-left: 120px;

		@media (max-width: $xl) {
			flex: 5;
			margin: 0 auto;
			padding-right: 2rem;
			padding-left: 2rem;
		}

		@media (max-width: $md) {
			& {
				margin: 0 $mobile-horizontal-margin;
				padding: $auth-layout-header-padding 0 0;
			}
		}
	}
}
</style>
