<template>
  <div>
    <div class="container">
      <label class="label" :for="id">
        <input
          :id="id"
          v-model="valueCopy"
          type="checkbox"
          :readonly="readonly"
          :disabled="disabled"
          :required="required"
          @input="handleModelInput"
        />
        <span class="checkmark"></span>
        <span class="content">
        {{ label }}
      </span>
      </label>
      <slot class="suffix-holder"></slot>
    </div>
    <div v-if="error" class="material-errors">
      <FormError :error="error" class="material-error"/>
    </div>
  </div>
</template>

<script>
import FormError from '@/shared/forms/FormError';

export default {
  name: 'MaterialCheckbox',
  components: {FormError},
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      valueCopy: null,
      focus: false,
      valid: true,
    };
  },
  watch: {
    value(newValue) {
      // This watch works from the code side of the 2-way-binding:
      this.copyValue(newValue);
    },
  },
  beforeMount() {
    // Here we are following the Vue2 convention on custom v-model:
    // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
    this.copyValue(this.value);
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', event.target.checked, event);
    },
    copyValue(value) {
      this.valueCopy = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/_variables";

$checkbox-size: 20px;

.container {
  display: block;
  position: relative;
  padding-left: calc(#{$checkbox-size} + .5rem);
  margin: .5rem 0;
  cursor: pointer;
  font-size: $caption;
  text-align: start;
  user-select: none;
}

.label {
  text-align: start;
  min-height: $checkbox-size;
  display: inline-block;
  color: $faded-white;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  opacity: 0;
  cursor: pointer;
  height: 1px;
  width: 1px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: $checkbox-size;
  min-width: $checkbox-size;
  height: $checkbox-size;
  border-radius: 2px;
  transition: .4s cubic-bezier(.25, .8, .25, 1);
  border: 2px solid rgba(255, 255, 255, .6);
  cursor: pointer;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $mantis-green;
  border-color: $mantis-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>
