<template>
	<AuthWrapper>
		<AuthLayout>
			<div class="aside">
				<div class="feedback-wrapper">
					<CustomerFeedback />
				</div>
			</div>
			<div class="main">
				<AuthFormWrapper>
					<PageTitle big>
						{{ $t('registration.title') }}
					</PageTitle>
					<RegistrationForm />
				</AuthFormWrapper>
			</div>
		</AuthLayout>
	</AuthWrapper>
</template>

<script>
import CustomerFeedback from '@/shared/customer-feedback/CustomerFeedback';
import AuthLayout from '@/shared/auth-layout/AuthLayout';
import AuthWrapper from '@/shared/auth-wrapper/AuthWrapper';
import AuthFormWrapper from '@/shared/auth-form-wrapper/AuthFormWrapper';
import PageTitle from '@/components/page-title/PageTitle';
import RegistrationForm from './RegistrationForm';
import apiClient from '../../api';
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

export default {
	name: 'Registration',
	components: {
		PageTitle,
		AuthWrapper,
		AuthLayout,
		CustomerFeedback,
		RegistrationForm,
		AuthFormWrapper,
	},
	beforeMount() {
		this.fetchCaptchaSiteKey();
	},
	methods: {
		async fetchCaptchaSiteKey() {
			const siteKey = await apiClient.getCaptchaSiteKey();
			Vue.use(VueReCaptcha, {
				siteKey,
				loaderOptions: {
					useRecaptchaNet: true,
					autoHideBadge: true,
				},
			});
		},
	},
};
</script>

<style scoped lang="scss">
@import '../../assets/style/_variables';

.feedback-wrapper {
	@media (max-width: $md) {
		& {
			display: none;
		}
	}
}
</style>
